const Style = ({ attributes, clientId }) => {
	const { alignment, btnTypo, btnColors, btnPadding, btnBorder, btnShadow } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${btnTypo?.googleFontLink ? `@import url(${btnTypo?.googleFontLink});` : ''}

		#ppbPrintPage-${clientId} .ppbPrintPage{
			text-align: ${alignment};
		}
		#ppbPrintPage-${clientId} .ppbPrintPage button{
			${btnTypo?.styles || 'font-size: 22px;'}
			${btnColors?.styles || 'color: #4527a4; background: #4527a433;'}
			padding: ${btnPadding?.styles || '5px 15px'};
			${btnBorder?.styles || 'border-radius: 5px;'}
			box-shadow: ${btnShadow?.styles || 'none'};
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;