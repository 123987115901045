import { render } from '@wordpress/element';

import './style.scss';
import Style from './Style';
import PrintPage from './PrintPage';

const printSection = printSelector => {
	const bodyContent = document.body.innerHTML;
	const printContents = printSelector && document.querySelector(printSelector);

	document.body.innerHTML = '';
	document.body.appendChild(printContents);

	window.print();

	document.body.innerHTML = bodyContent;
	location.reload();
}

// Print Page
document.addEventListener('DOMContentLoaded', () => {
	const allPrintPage = document.querySelectorAll('.wp-block-ppb-print-page');
	allPrintPage.forEach(printPage => {
		const attributes = JSON.parse(printPage.dataset.attributes);
		const { isPrintSection, sectionSelector } = attributes;

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<PrintPage attributes={attributes} />
		</>, printPage);

		// Event fire on print button
		const printBtn = document.querySelector(`#${printPage.id} .ppbPrintPage button`);
		printBtn && printBtn.addEventListener('click', e => {
			e.preventDefault();

			isPrintSection && sectionSelector ? printSection(sectionSelector) : window.print();
		});

		printPage?.removeAttribute('data-attributes');
	});
});