import { __ } from '@wordpress/i18n';
import { RichText } from '@wordpress/block-editor';

import icons from './Const/icons';

const PrintPage = ({ attributes, isBackend = false, wp }) => {
	const { isIcon, isText, btnText } = attributes;

	return <div className='ppbPrintPage'>
		<button>
			{isIcon && icons.print(30)}
			{isText && <>
				{isBackend ? <RichText tagName='span' value={btnText} onChange={val => wp.setAttributes({ btnText: val })} placeholder={__('Button Text', 'print-page')} inlineToolbar /> : btnText && <RichText.Content tagName='span' value={btnText} />}
			</>}
		</button>
	</div>
}
export default PrintPage;